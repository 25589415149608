<template>
  <el-tooltip
      class="box-item"
      effect="dark"
      content="重命名"
      placement="bottom"
      :disabled="disableTooltip || !canWrite"
  >
    <div class="editor-wrap"
         :class="{
            editing:editMode
         }"
         ref="wrapperRef">
      <input
          class="edit-title-input"
          ref="inputRef"
          v-model="titleCopy"
          @input="handleInput"
          @keydown="handleKeydown"
          :disabled="!canWrite"
      />
      <span
          class="et-scale"
          ref="scaleRef"
      >{{titleCopy}}</span>
    </div>
  </el-tooltip>
</template>

<script>
import {colorLog, DomEventListenerManager, MetaMessage} from "../util";
import {watch, ref, reactive, unref, nextTick, toRef, onMounted, onBeforeUnmount, computed} from "vue";
import anime from "../assets/js/anime.es";
import {ElMessage} from "element-plus";


export default {
  name: "EditTitle",
  props:{
    mode:{
      required:true
    },
    uploadFn:{
      required:false
    },
    disableTooltip:{
      required:false,
    },
    title:{
      required:true
    },
    maxLength:{
      required:false
    },
    canWrite:{
      required:false,
      default: true
    }
  },
  setup(props,ctx){
    const editMode = ref(false);
    const domEventListenerManager = new DomEventListenerManager();
    const inputRef = ref(null);
    const scaleRef = ref(null);
    const wrapperRef = ref(null);

    const titleBackup = ref('');
    watch(toRef(props,'mode'),async (modeVal) => {
      editMode.value = modeVal;
      if(modeVal) {
        titleBackup.value = props.title;
        await nextTick();
        inputRef.value.focus();
      }
    },{
      immediate:true,
    });
    watch(editMode,(modeVal) => {
      ctx.emit('update:mode',modeVal);
    });

    const titleCopy = ref('');
    watch([toRef(props,'title'),toRef(props,'maxLength'),editMode],() => {
      let str = props.title;
      if(!editMode.value && props.maxLength && str.length >= props.maxLength){
        str = str.substring(0,props.maxLength) + '...';
      }
      titleCopy.value = str;
    },{
      immediate:true
    });

    watch(titleCopy,async (newTitle) => {
      await nextTick();
      setInputWidth();
    });

    const handleInput = function(event){
      titleCopy.value = event.target.value;
      ctx.emit('update:title',event.target.value);
    }
    const handleKeydown = function(event){
      switch(event.key){
        case 'Enter':
          switchToDisplayMode(true);
          break;
        case 'Escape':
          switchToDisplayMode(false);
          break;
      }
    }

    //注册一个全局监听事件,来控制编辑和展示的模式
    domEventListenerManager.registerListener(window,'click',(event) => {
      const isClickedWithinWrapper = wrapperRef.value.contains(event.target);
      if(isClickedWithinWrapper) {
        switchToEditMode();
      }else{
        switchToDisplayMode(true);
      }
    },{capture:true});

    const toggleMode = () => {
      if(editMode.value){
        switchToDisplayMode();
      }else{
        switchToEditMode();
      }
    }
    const switchToEditMode = () => {
      if(!props.canWrite) return;
      editMode.value = true;
    }
    const  switchToDisplayMode = (save) => {
      if(save && titleCopy.value !== titleBackup.value) {
        if(!/\S/g.test(titleCopy.value)){
          //都是空格
          ElMessage.error('内容不能为空');
          return;
        }
        editMode.value && props.uploadFn?.(titleCopy.value);
      }else{
        ctx.emit('update:title',titleBackup.value);
      }

      editMode.value = false;
      inputRef.value.blur();
    }

    onBeforeUnmount(() => domEventListenerManager.removeListener());
    const getScaleWidth = () => {
      return anime.get(scaleRef.value,'width','px');
    }
    const setInputWidth = (width = getScaleWidth()) => {
      inputRef.value.style.setProperty('width',width);
    }
    onMounted(setInputWidth);

    return {
      inputRef,
      scaleRef,
      wrapperRef,
      editMode,
      titleCopy,
      handleInput,
      handleKeydown
    }
  },
}
</script>

<style scoped>
.editor-wrap{
  --titleFontSize:21px;

  display: inline-block;
  background-color: white;
  line-height: 1;
  position: relative;
  font-size: 0;
  box-sizing:border-box;
  position: relative;
  padding: 4px;
  z-index: 1;
}

.editor-wrap.editing,.editor-wrap:hover{
  outline: 1px solid #DDD;
}

.edit-title-input{
  all:unset;

  margin: 0;
  font-size: var(--titleFontSize);
  font-weight: 400;
  padding: 0;
  color: #2c3e50;
  border: none;
}
.edit-title-input.disabled{
  user-select: none;
}
.et-display{
  font-size: var(--titleFontSize);
}
.et-scale{
  font-size: var(--titleFontSize);
  font-weight: 400;

  white-space: pre;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}
</style>
